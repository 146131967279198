/*
Template Name: Ubold - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 5.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Custom Bootstrap Css File
*/

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "../../config/modern/variables";
@import "./node_modules/bootstrap/scss/bootstrap";

//Components
@import "../../custom/components/accordions";
@import "../../custom/components/badge";
@import "../../custom/components/backgrounds";
@import "../../custom/components/breadcrumb";
@import "../../custom/components/buttons";
@import "../../custom/components/card";
@import "../../custom/components/dropdown";
@import "../../custom/components/forms";
@import "../../custom/components/modal";
@import "../../custom/components/nav";
@import "../../custom/components/pagination";
@import "../../custom/components/popover";
@import "../../custom/components/progress";
@import "../../custom/components/reboot";
@import "../../custom/components/tables";
@import "../../custom/components/type";

.text-right{
    text-align: right;
}
.v-middle{
    vertical-align: middle;
}
.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}
.bg-white-50{
    background-color: #ffffff7e;
}
.bg-gradiente{
    background: $bg-leftbar-gradient;
    background: linear-gradient(15deg, $bg-leftbar-gradient 0%, $bg-leftbar-brand 100%);
}
.item-hover:focus, .item-hover:hover {
    color: #616971;
    background-color: #f3f7f9;
}
.text-brow{
    color: #831c1c;
}
.bg-soft-danger-50{
    background-color: rgba(243, 93, 93, 0.082)!important;
}
.rounded-5{
    border-radius: 5px;
}
.rounded-10{
    border-radius: 10px;
}
.rounded-15{
    border-radius: 15px;
}
.rounded-20{
    border-radius: 20px;
}
.rounded-25{
    border-radius: 25px;
}
.rounded-30{
    border-radius: 30px;
}
.rounded-40{
    border-radius: 40px;
}
.rounded-50{
    border-radius: 50px;
}